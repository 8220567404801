import React from 'react';
import Link from "@mui/material/Link";

type UrlProps = {
  href: string
  external?: boolean //internal link or external link
  target?: '_blank'
  className?: string
  children: React.ReactNode
  onCLick?: () => void
}

const Url: React.FC<UrlProps> = (props: UrlProps) => {
  const { href, external, target, className, children } = props;

  const handleOnClick = () => {
    props.onCLick && props.onCLick()
  }

  if (!external) {
    return <Link href={href} className={className} target={target} onClick={handleOnClick}>{children}</Link>;
  }

  return <Link href={href} rel="nofollow"
               target="_blank" className={className} onClick={handleOnClick}>{children}</Link>;
};

export default Url;
