import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Url from "../links/Url";

const Footer: React.FC = () => {
  return (
    <Stack direction='row' sx={{
      position: {xs: 'relative', md: 'absolute'},
      bottom: {xs: 'none', md:'10px'},
      right: {xs: 'none', md:'2em'},
    }}>
      <Typography variant='body2'
                  sx={{color: theme => theme.palette.text.disabled}}>
        © {(new Date()).getFullYear()}. All Rights Reserved. Made
        with <FavoriteBorderIcon fontSize={"small"}
                                 sx={{color: '#B71C1C', verticalAlign: 'text-top'}}/> by
        <Url href="https://www.linkedin.com/in/viduraadikari/" target="_blank" external>
          {" "}Vidura Adikari
        </Url>
      </Typography>
    </Stack>
  )
};

export default Footer;
