import React from 'react';
import {Helmet} from "react-helmet";

type SEOSnippetsProps = {
  title: string,
  description: string
}

const SEOSnippets: React.FC<SEOSnippetsProps> = (props: SEOSnippetsProps) => {

  const {title, description} = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>
    </Helmet>
  )
};

export default SEOSnippets;