import React from 'react';
import Container from "@mui/material/Container";
import HeroCard from "../components/deck/elements/cards/HeroCard";
import Hero from "../components/deck/pallets/hero/Hero";
import HomeHead from "../components/deck/cartons/seo/HomeHead";

const Home: React.FC = () => {

  return (
    <Container maxWidth="xl">
      <HomeHead/>
      <HeroCard>
        <Hero/>
      </HeroCard>
    </Container>
  )
};

export default Home;