import React from 'react';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

type HeroCardProps = {
  children: React.ReactNode
}

const HeroCard: React.FC<HeroCardProps> = (props: HeroCardProps) => {

  const {children} = props;

  const bgFallbackDark = "rgb(74,20,140)";
  const bgFallbackLight = "rgb(108,149,181)";
  // const darkBG = `linear-gradient(333deg, rgba(74,20,140,1) 0%,
  // rgba(134,88,190,0.7021009087228641) 28%, rgba(86,199,222,1) 100%)`;
  const darkBG = `linear-gradient(333deg, rgba(26, 25, 61, 1) 0%, 
  rgba(40, 44, 83, 0.7) 28%, rgba(53, 62, 102, 1) 100%)`;
  const lightBG = `linear-gradient(321deg, rgba(108,149,181,1) 0%, rgba(178,190,195,1) 30%, rgba(207,216,220,1) 100%)`;


  return (
    <Box sx={{
      height: '100vh',
      maxHeight: { md: '100vh', lg: '800px'},
      width: '100%',
      mt: {xs: 11, sm: 11, md: 0},
      px: {xs: 0, sm: 2, md: 2},
      py: 1,
    }}>
      <Paper elevation={4} sx={{
        bgcolor: theme => theme.palette.mode === "light" ? bgFallbackLight : bgFallbackDark,
        background: theme => theme.palette.mode === "light" ? lightBG : darkBG,
        borderRadius: '1em',
        height: 'calc(100% - 2em)',
        px: 4,
        overflow: 'hidden',
      }}>

        {children}

      </Paper>
    </Box>
  );
};

export default HeroCard;