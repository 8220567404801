import React from 'react';
import Box from "@mui/material/Box";
import {ReactComponent as HeroBG} from '../../../../assets/images/home/herobg.svg';

const HeroFigure: React.FC = () => {

  return (
    <Box sx={{
      '& img': {
        transform: 'scaleX(-1) rotate(4deg)',
        width: {xs: '150%', md: 'auto'},
      }
    }}>
      <Box sx={{
        display: 'none',
        position: 'absolute',
        height: '80vh',
        zIndex: '-1',
        width: '45vw',
        maxWidth: '100%',
      }}>
        <HeroBG/>
      </Box>
      <img src="https://s3.amazonaws.com/cdn.vidura/va/vidura.webp" alt='portrait of Vidura Adikari'/>
    </Box>
  );
};

export default HeroFigure;