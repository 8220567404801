import React from 'react';
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import NavLink from "../../elements/links/NavLink";
import {AppRoute} from "../../../../routes/routes";
import ThemeSwitch from "../../elements/switch/ThemeSwitch";
import {grey} from "@mui/material/colors";

type AppNavBarProps = {
  appRoutes: AppRoute[]
  onThemeChange: (isDarkTheme: boolean) => void
  prefersDarkMode: boolean
}

const AppNavBar: React.FC<AppNavBarProps> = (props: AppNavBarProps) => {
  const {appRoutes, prefersDarkMode} = props;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="absolute" sx={{
      bgcolor: 'transparent',
      background: "none",
      boxShadow: theme => ({
        md: 'none',
        xs: `0 2px 4px -1px ${theme.palette.mode === "light" ? grey[500] : '#000000'}`
      }),
      py: {xs: 1, md: 2},
    }}>
      <Container maxWidth="lg">
        <Toolbar sx={{
          justifyContent: "flex-end",
        }}>

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'},}}>
            <IconButton
              size="large"
              aria-label="open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{
                color: theme => theme.palette.text.secondary
              }}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              {appRoutes.map((route: AppRoute) => (
                <MenuItem key={route.path} onClick={handleCloseNavMenu}>
                  <NavLink key={route.path} text={route.linkText} path={route.path} onItemClick={handleCloseNavMenu}/>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex', justifyContent: "flex-end",}}}>
            {appRoutes.map((route: AppRoute) =>
              <NavLink key={route.path} text={route.linkText} path={route.path} onItemClick={handleCloseNavMenu}/>)}
          </Box>

          <Box sx={{flexGrow: 0, mr: {xs: 2, md: 16}}}>
            <ThemeSwitch defaultChecked={prefersDarkMode} onChange={props.onThemeChange}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppNavBar;
