import React from 'react';
import SEOSnippets from "../../elements/seo/SEOSnippets";

const HomeHead: React.FC = () => {
  const title = 'Vidura Adikari | Senior Software Engineer ReactJS';
  const description = `Vidura Adikari Senior Software Engineer with more then 10 years experience in custom
          software development, Agile coach, ReactJS, Typescript SE and entrepreneur`;

  return <SEOSnippets title={title} description={description}/>
};

export default HomeHead;