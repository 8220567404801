import React, {useEffect} from 'react';
import Button from "@mui/material/Button";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

type ThemeSwitchProps = {
  defaultChecked: boolean
  onChange: (isChecked: boolean) => void
}

const ThemeSwitch: React.FC<ThemeSwitchProps> = (props: ThemeSwitchProps) => {
  const {defaultChecked, onChange} = props;
  const [checked, setChecked] = React.useState(defaultChecked);

  const handleOnClick = () => {
    setChecked(checked => !checked);
  }

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  return (
    <Button variant="outlined" onClick={handleOnClick} sx={{
      px: '.4em',
      minWidth: "auto",
      borderRadius: '50%',
      border: '2px solid',
      borderColor: theme => theme.palette.primary.main,
      '&:hover': {
        border: '2px solid',
      }
    }}
    title={`change theme to ${checked ? 'light' : 'dark'}`}>
      {checked && <DarkModeIcon sx={{
        color: theme => theme.palette.primary.main
      }}/>}

      {!checked && <LightModeIcon sx={{
        color: theme => theme.palette.secondary.main
      }}/>}
    </Button>
  )
};

export default ThemeSwitch;