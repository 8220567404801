import React, {PropsWithChildren, Suspense, useEffect, useState} from 'react';
import {Theme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {getTheme} from "../util/theme/Theme";
import {AppRoute, appRoutes, nonNavRoutes} from "../routes/routes";
import AppNavBar from "../components/deck/cartons/navbars/AppNavBar";
import Footer from "../components/deck/elements/footer/Footer";

type HomeProps = {
  prefersDarkMode: boolean
}

const Layout: React.FC<HomeProps> = (props: PropsWithChildren<HomeProps>) => {

  const {prefersDarkMode} = props;

  const [isDark, setIsDark] = useState(prefersDarkMode);

  useEffect(() => {
    setIsDark(prefersDarkMode);
  }, [prefersDarkMode]);

  const handleOnThemeChange = (isDarkTheme: boolean) => {
    setIsDark(isDarkTheme);
  }

  const theme: Theme = React.useMemo(() => {
    return getTheme(isDark ? 'dark' : 'light');
  }, [isDark]);

  const menu = appRoutes.concat(nonNavRoutes).map((route: AppRoute) =>
    <Route key={route.path} path={route.path}
           element={<Suspense fallback={<div>loading...</div>}>
             {<route.component/>}
           </Suspense>
           }/>);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <AppNavBar prefersDarkMode={prefersDarkMode} onThemeChange={handleOnThemeChange} appRoutes={appRoutes}/>

        <Routes>
          {menu}
        </Routes>

        <Footer/>
      </Router>

    </ThemeProvider>
  )
};

export default Layout;
