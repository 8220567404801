import React from 'react';
import './App.css';
import Layout from "./view/Layout";

function App() {
  return (
    <Layout prefersDarkMode={false}/>
  );
}

export default App;
