import React, {PropsWithChildren} from 'react';
import Typography from "@mui/material/Typography";

type HeroTitlesProps = {
  h1Text: string
}

const HeroTitles: React.FC<HeroTitlesProps> = (props: PropsWithChildren<HeroTitlesProps>) => {
  
  const {h1Text} = props;
  
  return (
    <Typography variant="h1" sx={{
      color: theme => theme.palette.text.primary,
    }}>
      {h1Text}
    </Typography>
  )
};

export default HeroTitles;