import {createTheme, Theme, ThemeOptions} from "@mui/material/styles";
import {blue, cyan, grey, purple, teal} from "@mui/material/colors/";
import {makeResponsive} from "./themeUtils";

const commonThemeOptions: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: [
      '"M PLUS Rounded 1c"',
      'sans-serif',
    ].join(','),
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          fontWeight: 600,
        },
        sizeMedium: {
          padding: '6px 22px',
        },
        sizeLarge: {
          padding: '9px 32px',
          fontSize: '1.2em',
          fontWeight: 600,
        }
      }
    },
  },
}

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#263238',
      paper: '#263238'
      // paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: grey[300],
      disabled: grey[500],
    },
    primary: {
      dark: cyan[800],
      main: cyan[600],
      light: cyan[200],
      contrastText: grey[50]
    },
    secondary: {
      dark: purple[700],
      main: purple[500],
      light: purple[200],
      contrastText: blue[400]
    },
  },
  ...commonThemeOptions,
}

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#f1f1f1',
      paper: '#FFFFFF'
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
      disabled: grey[600],
    },
    primary: {
      dark: cyan[800],
      main: teal[400],
      light: teal[200],
      contrastText: grey[50]
    },
    secondary: {
      main: cyan[600],
      light: cyan[600],
      dark: cyan[900],
      contrastText: blue[800]
    },
    common: {
      white: '#fefefe',
      black: '#000000'
    }
  },
  ...commonThemeOptions,
}

export const getTheme = (mode: 'dark' | 'light'): Theme => {
  const theme: Theme = mode === 'dark' ? createTheme(darkThemeOptions) : createTheme(lightThemeOptions);
  return makeResponsive(theme);
}