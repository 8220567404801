import React from 'react';
import {NavLink as RouterNavLink} from 'react-router-dom';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

type NavLinkProps = {
  text: string
  path: string
  onItemClick?: () => void
}

const NavLink: React.FC<NavLinkProps> = (props: NavLinkProps) => {

  const {text, path, onItemClick} = props;

  return (
    <Box sx={{
      minWidth: 120,
      '& a': {
        color: theme => theme.palette.text.secondary,
        textDecoration: 'none',
        fontWeight: 600,
      },
      '& :hover': {
        color: theme => theme.palette.text.primary,
      },
      '& a.active > button': {
        color: theme => theme.palette.text.primary,
      }
    }}>
      <RouterNavLink to={path} onClick={onItemClick}>
        <Button variant={"text"} sx={{borderRadius: '7px', textTransform: 'capitalize', mx: 1, color: 'inherit'}}>
          {text}
        </Button>
      </RouterNavLink>
    </Box>
  )
};

export default NavLink;