import React from 'react';
import Box from "@mui/material/Box";

const BGCircle: React.FC = () => {
  return (
    <Box sx={{
      display: {xs: 'none', md: 'block'},
      width: { md: '21em', xl: '23em'},
      height: { md: '21em', xl: '23em'},
      borderRadius: '50%',
      border: '3em solid',
      // borderColor: 'rgba(156, 39, 176, .3)',
      borderColor: 'rgba(0, 188, 212, .3)',
      marginLeft: {md: '-8em', lg: '-14em', xl: '-10em'},
      mt: {md: '3em', lg:'1em'},
      position: 'relative',
    }}/>
  )
};

export default BGCircle;