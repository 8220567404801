import React from "react";
import {ROUTE_PATHS} from "./routePaths";
import Home from "../view/Home";

// const About = React.lazy(() => import(`../components/deck/containers/pages/About`));
const Portfolio = React.lazy(() => import(`../components/deck/containers/pages/Portfolio`));
const HowICode = React.lazy(() => import(`../components/deck/containers/pages/HowICode`));
const TokenQueueCode = React.lazy(() => import(`../components/deck/containers/pages/TokenQueueCode`));
const Contact = React.lazy(() => import(`../components/deck/containers/pages/Contact`));

type AppRoute = {
  path: string,
  linkText: string,
  component: any,
}

export const appRoutes: AppRoute[] = [
  {path: ROUTE_PATHS.HOME, linkText: 'Home', component: Home},
  // {path: ROUTE_PATHS.ABOUT, linkText: 'About', component: About},
  {path: ROUTE_PATHS.PORTFOLIO, linkText: 'Portfolio', component: Portfolio},
  {path: ROUTE_PATHS.HOW_I_CODE, linkText: 'How I Code', component: HowICode},
  {path: ROUTE_PATHS.CONTACT, linkText: 'Contact', component: Contact},
]

export const nonNavRoutes: AppRoute[] = [
  {path: ROUTE_PATHS.HOW_I_CODE_TOKEN, linkText: 'How I Code', component: TokenQueueCode},

];

export type {AppRoute};
