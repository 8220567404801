import React from 'react';
import Grid from "@mui/material/Grid";
import HeroTextSection from "../../cartons/hero/HeroTextSection";
import HeroFigure from "../../cartons/hero/HeroFigure";

const Hero: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{pt: {xs: 2, md: 10}}}/>
      <Grid item xs={12} md={6}>
        <HeroTextSection/>
      </Grid>

      <Grid item xs={12} md={6}>
        <HeroFigure/>
      </Grid>
    </Grid>
  )
};

export default Hero;