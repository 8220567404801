import React, {PropsWithChildren} from 'react';
import Typography from "@mui/material/Typography";

type HeroDescriptionProps = {
  description: string
}

const HeroDescription: React.FC<HeroDescriptionProps> = (props: PropsWithChildren<HeroDescriptionProps>) => {
  
  const {description} = props;
  
  return (
    <Typography variant='body2' sx={{
      mt: 2,
      pr: {xs: 0, md: 15}
    }}>
      {description}
    </Typography>
  )
};

export default HeroDescription;