import React from 'react';
import Stack from "@mui/material/Stack";
import BGCircle from "../../elements/hero/BGCircle";
import HeroTitles from "./HeroTitles";
import HeroDescription from "../../elements/hero/HeroDescription";

const HeroTextSection: React.FC = () => {
  return (
    <Stack sx={{
      pt: {xs: 0, md: 22, lg: 14, xl: 25},
      px: {lg: 6, xxl: 12},
    }}>
      <HeroTitles h1Text="Build systems that improve quality of life."/>

      <HeroDescription description={`Hi, my name is Vidura Adikari. With 12+ years of experience in custom software 
      development I create applications that solve major problems.`}/>

      <BGCircle/>
    </Stack>
  )
};

export default HeroTextSection;
